import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

function Parallax ({ children, trigger, from, to, settings, style }) {
    const element = useRef();
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        gsap.fromTo(
            element.current,
            from, 
            {
                ...to,
                scrollTrigger: {
                    trigger: trigger ? trigger : element.current,
                    ...settings
                  },
            },
        )        
    }, []);

    return (
        <div style={style} ref={element}>
            {children}
        </div>
    );
}

Parallax.defaultProps = {
    from: { scale: 1 },
    to: { scale: 1},
    settings: { start: "top center" },
    style: { position: 'relative' }
}



export default Parallax;